import axios from 'axios';
//import { getLoginToken} from '../../helpers/Util';



const getHeader = () => {
    const token = localStorage.getItem("user-token");
    let headers = {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": `Bearer ${token}`
    };
    return headers;
}

const URL = process.env.REACT_APP_API_URL;

const getVendors = async () => {

    return await axios.get(`${URL}/vendors`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    });
}

const getVendorsById = async (id) => {

    return await axios.get(`${URL}/vendors/${id}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    });
}

const updateVendorById = async (id, payload) => {
    return await axios.patch(`${URL}/vendors/${id}`, payload, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const deleteVendorById = async (id) => {
    return await axios.delete(`${URL}/vendors/${id}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    });
}

const addVendor = async (payload) => {
    return await axios.post(`${URL}/vendors`, payload, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getQcs = async () => {

    return await axios.get(`${URL}/qcs`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    });
}

const getQcById = async (id) => {

    return await axios.get(`${URL}/qcs/${id}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    });
}

const updateQcById = async (id, payload) => {
    return await axios.patch(`${URL}/qcs/${id}`, payload, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const deleteQcById = async (id) => {
    return await axios.delete(`${URL}/qcs/${id}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    });
}


const getLocation = async () => {

    return await axios.get(`${URL}/locations`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    });
}

const getLocationById = async (id) => {

    return await axios.get(`${URL}/locations/${id}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    });
}

const deleteLocationById = async (id) => {
    return await axios.delete(`${URL}/locations/${id}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    });
}

const updateLocationById = async (id, payload) => {
    return await axios.patch(`${URL}/locations/${id}`, payload, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getTable = async () => {

    return await axios.get(`${URL}/tables`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    });
}

const getTableById = async (id) => {

    return await axios.get(`${URL}/tables/${id}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    });
}

const updateTableById = async (id, payload) => {
    return await axios.patch(`${URL}/tables/${id}`, payload, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const deleteTableById = async (id) => {
    return await axios.delete(`${URL}/tables/${id}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    });
}

const addTable = async (payload) => {
    return await axios.post(`${URL}/tables`, payload, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getShop = async () => {

    return await axios.get(`${URL}/shops`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    });
}
const getShopActive = async () => {

    return await axios.get(`${URL}/shops/active`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    });
}

const getShopById = async (id) => {

    return await axios.get(`${URL}/shops/${id}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    });
}

const updateShopById = async (id, payload) => {
    return await axios.patch(`${URL}/shops/${id}`, payload, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const deleteShopById = async (id) => {
    return await axios.delete(`${URL}/shops/${id}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    });
}

const addShop = async (payload) => {
    return await axios.post(`${URL}/shops`, payload, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getDrawingTypes = async () => {

    return await axios.get(`${URL}/drawing-types`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    });
}

const getDrawingTypesById = async (id) => {

    return await axios.get(`${URL}/drawing-types/${id}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    });
}

const updateDrawingTypeById = async (id, payload) => {
    return await axios.patch(`${URL}/drawing-types/${id}`, payload, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const deleteDrawingById = async (id) => {
    return await axios.delete(`${URL}/drawing-types/${id}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    });
}

const addDrawingType = async (payload) => {
    return await axios.post(`${URL}/drawing-types`, payload, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getFieldCrews = async () => {

    return await axios.get(`${URL}/field-crews`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    });
}

const getFieldCrewsById = async (id) => {

    return await axios.get(`${URL}/field-crews/${id}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    });
}

const updateFieldCrewsById = async (id, payload) => {
    return await axios.patch(`${URL}/field-crews/${id}`, payload, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const addFieldCrew = async (payload) => {
    return await axios.post(`${URL}/field-crews`, payload, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const deleteFieldCrewById = async (id) => {
    return await axios.delete(`${URL}/field-crews/${id}`, {
        headers: getHeader()
    }).then((reponse) => {
        return Promise.resolve(reponse);
    }).catch((error) => {
        return Promise.reject(error);
    });
}

const getShopMaintenanceList = async () => {
    return await axios.get(`${URL}/maintenance-shop-maintenance-types`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    });
}
const getShopMaintenanceItemById = async (id) => {
    return await axios.get(`${URL}/maintenance-shop-maintenance-types/${id}`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    });
}
const updateShopMaintenanceItemById = async (id, payload) => {
    return await axios.patch(`${URL}/maintenance-shop-maintenance-types/${id}`, payload, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    });
}
const deleteShopMaintenanceItemById = async (id) => {
    return await axios.delete(`${URL}/maintenance-shop-maintenance-types/${id}`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    });
}

const checkLinkedShop = async (id) => {
    return await axios.get(`${URL}/maintenance-shop-maintenance-types/${id}/maintenance-shop-data`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    });
}
const addShopMaintenanceItem = async (payload) => {

    return await axios.post(`${URL}/maintenance-shop-maintenance-types`, payload, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    });
}

const getFieldMaintenanceList = async () => {
    return await axios.get(`${URL}/maintenance-field-types`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    });
}
const addFieldMaintenanceItem = async (payload) => {
    return await axios.post(`${URL}/maintenance-field-types`, payload, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    });
}
const deleteFieldMaintenanceById = async (id) => {
    return await axios.delete(`${URL}/maintenance-field-types/${id}`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    });
}
const getFieldMaintenanceItemById = async (id) => {
    return await axios.get(`${URL}/maintenance-field-types/${id}`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    });
}

const updateFieldMaintenanceItemById = async (id, payload) => {
    return await axios.patch(`${URL}/maintenance-field-types/${id}`, payload, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    });
}
const addfieldmaintenaceitem = async (payload) => {
    return await axios.post(`${URL}/maintenance-field-maintenance-types`, payload, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    });
}

const getfieldmaintenaceitem = async () => {
    return await axios.get(`${URL}/maintenance-field-maintenance-types`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    });
}

const getfieldmaintenaceitembyid = async (id) => {
    return await axios.get(`${URL}/maintenance-field-maintenance-types/${id}`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    });
}

const deletefieldmaintenaceitembyid = async (id) => {
    return await axios.delete(`${URL}/maintenance-field-maintenance-types/${id}`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    });
}
const updatefieldmaintenaceitembyid = async (id, payload) => {
    return await axios.patch(`${URL}/maintenance-field-maintenance-types/${id}`, payload, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    });
}

//Grade module api functions starts
const getGrade = async () => {
    return await axios.get(`${URL}/grades`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    });
}
const getGradeById = async (id) => {
    return await axios.get(`${URL}/grades/${id}`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    });
}
const addGrade = async (payload) => {
    return await axios.post(`${URL}/grades`, payload, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    });
}
const updateGradeById = async (id, payload) => {
    return await axios.patch(`${URL}/grades/${id}`, payload, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    });
}
const deleteGradeById = async (id) => {
    return await axios.delete(`${URL}/grades/${id}`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    });
}
//bay module api function starts
const getBays = async () => {
    return await axios.get(`${URL}/bays`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    });
}
const getBayById = async (id) => {
    return await axios.get(`${URL}/bays/${id}`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    });
}
const addBay = async (payload) => {
    return await axios.post(`${URL}/bays`, payload, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    });
}
const updateBayById = async (id, payload) => {
    return await axios.patch(`${URL}/bays/${id}`, payload, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    });
}
const deleteBayById = async (id) => {
    return await axios.delete(`${URL}/bays/${id}`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    });
}
const getProjectManagers = async () => {
    return await axios.get(`${URL}/users/projectmanagers`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    });
}
const getColorCodeList = async () => {
    return await axios.get(`${URL}/color-codes`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    });
}
const getAvailableColorCodes = async (userId) => {
    return await axios.get(`${URL}/color-codes/available-code/${userId}`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    });
}
const addColorCode = async (payload) => {
    return await axios.post(`${URL}/color-codes`, payload, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    });
}
const deleteColorCode = async (id, code) => {
    let str = code.substring(1, code.length);
    return await axios.delete(`${URL}/color-codes/${id}/${str}`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response);
    }).catch((error) => {
        return Promise.reject(error);
    });
}
//certificate list api calling--------------

const getCertificates = async () => {
    return await axios.get(`${URL}/certifications`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const addCertificate = async (payload) => {
    return await axios.post(`${URL}/certifications`, payload, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const deleteCertificate = async (id) => {
    return await axios.delete(`${URL}/certifications/${id}`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const getCertificateById = async (id) => {
    return await axios.get(`${URL}/certifications/${id}`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const updateCertificateById = async (id, payload) => {
    return await axios.patch(`${URL}/certifications/${id}`, payload, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}

//  Titles api call start 
const getTitles = async () => {
    return await axios.get(`${URL}/titles`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const addTitles = async (payload) => {
    return await axios.post(`${URL}/titles`, payload, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const deleteTitles = async (id) => {
    return await axios.delete(`${URL}/titles/${id}`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}

//  titles api call end  

//  /shop-designations api call start 
const getShopDesignation = async () => {
    return await axios.get(`${URL}/shop-designations`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const addShopDesignation = async (payload) => {
    console.log("calling api master ");
    return await axios.post(`${URL}/shop-designations`, payload, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const deleteShopDesignation = async (id) => {
    return await axios.delete(`${URL}/shop-designations/${id}`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}

//  /shop-designations api call end  

// equipment type api call starts

const getEquipmentType = async () => {
    return await axios.get(`${URL}/equipment-types`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const addEquipment = async (payload) => {
    return await axios.post(`${URL}/equipment-types`, payload, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const getEquipmentById = async (id) => {
    return await axios.get(`${URL}/equipment-types/${id}`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const deleteEquipmentTypeById = async (id) => {
    return await axios.delete(`${URL}/equipment-types/${id}`, {
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}
const updateEquipmentById = async(id, payload) => {
    return await axios.patch(`${URL}/equipment-types/${id}`, payload,{
        headers: getHeader()
    }).then((response) => {
        return Promise.resolve(response)
    }).catch((error) => {
        return Promise.reject(error);
    })
}

const masterDataService = {
    getVendors,
    getVendorsById,
    updateVendorById,
    addVendor,
    getQcs,
    getQcById,
    updateQcById,
    getLocation,
    getLocationById,
    updateLocationById,
    getTable,
    getTableById,
    updateTableById,
    addTable,
    getShop,
    getShopActive,
    getShopById,
    updateShopById,
    addShop,
    getDrawingTypes,
    getDrawingTypesById,
    updateDrawingTypeById,
    addDrawingType,
    getFieldCrews,
    getFieldCrewsById,
    updateFieldCrewsById,
    addFieldCrew,
    deleteVendorById,
    deleteQcById,
    deleteLocationById,
    deleteTableById,
    deleteShopById,
    deleteDrawingById,
    deleteFieldCrewById,
    getShopMaintenanceList,
    getShopMaintenanceItemById,
    updateShopMaintenanceItemById,
    addShopMaintenanceItem,
    deleteShopMaintenanceItemById,
    checkLinkedShop,
    addFieldMaintenanceItem,
    getFieldMaintenanceList,
    deleteFieldMaintenanceById,
    getFieldMaintenanceItemById,
    updateFieldMaintenanceItemById,
    addfieldmaintenaceitem,
    getfieldmaintenaceitem,
    getfieldmaintenaceitembyid,
    deletefieldmaintenaceitembyid,
    updatefieldmaintenaceitembyid,
    //grade module starts
    getGrade,
    getGradeById,
    addGrade,
    updateGradeById,
    deleteGradeById,
    //bay module starts
    getBays,
    getBayById,
    addBay,
    updateBayById,
    deleteBayById,
    //get all the project managers--------
    getProjectManagers,
    getColorCodeList,
    addColorCode,
    deleteColorCode,
    getAvailableColorCodes,
    //certficate module api integrations-------
    getCertificates,
    getCertificateById,
    addCertificate,
    deleteCertificate,
    updateCertificateById,
    // shop designation module api 
    getShopDesignation,
    addShopDesignation,
    deleteShopDesignation,
    // titles module api 
    getTitles,
    addTitles,
    deleteTitles,
    //equipment types api calls//
    getEquipmentType,
    addEquipment,
    getEquipmentById,
    deleteEquipmentTypeById,
    updateEquipmentById

}

export default masterDataService;